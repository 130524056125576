*,
*::before,
*::after {
  box-sizing: border-box;
}

/* General styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --standard-background: white;
  --juv-red: #be1621;
  --juv-red-dark: #98121a;
  --standard-height: 2rem;
  --standard-spacing: 0.5rem;
  --small-spacing: 0.4rem;
  --wide-spacing: 2rem;
  --button-height: 3rem;
  --button-min-width: 3rem;
  --standard-input-width: 12rem;

  background: var(--standard-background);

  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  font-size: large;
  font-weight: bold;
  height: 100%;
}
input {
  font-size: normal;
  height: 100%;
  width: 70%;
}
select {
  height: 100%;
  width: 70%;
  background: white;
}

/* Toast message */
.toast {
  top: 80%;
  left: 50%;
  transform: translate(-50%);
  border: 1px solid var(--juv-red) !important;
  z-index: 3 !important;
}
.toast div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.toast p {
  font-size: large;
  font-weight: bold;
  margin: 0;
}

.input-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--standard-height);
  margin: var(--small-spacing) 0;
}

/* Button styling */
.blank-button {
  all: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}

.juv-button button{
  all: initial;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-size: x-large;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.juv-button {
  display: flex;
  justify-content: center;
  height: var(--button-height);
  min-width: var(--button-min-width);
  background: var(--juv-red);
  cursor: pointer;
}
.juv-button:hover {
  background: var(--juv-red-dark);
}
.juv-button.activated {
  background: var(--juv-red-dark) !important;
}
.juv-button.activated:hover {
  background: var(--juv-red) !important;
}
.juv-button.small p{
  font-size: small;
  margin: 0 !important;
}

.juv-button p {
  margin: 0 1rem;
}

.juv-button svg {
  /*margin: 0.45rem 0.6rem 0.15rem 0.6rem;*/
}

/* Round button */
.juv-button.round {
  border-radius: 30px;
}

/* Top top and New buttons and page nav buttons */
.juv-button.create-new,
.juv-button.to-top {
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  top: 90%;
  left: 10%;
}
.juv-button.create-new {
  left: 90%;
  transform: translate(50%) scale(110%);
}
.juv-button.to-top {
  transform: translate(-150%) scale(110%);
}
.juv-button.page {
  transform: scale(80%);
}

/* Main Container styling */
.container {
  max-width: 80%;
  margin: auto;
}

/* Hover boxes that float on top */
.hover-box {
  padding: 2rem 3rem;
  position: fixed;
  background: var(--standard-background);
  border: 1px solid gray;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 1;
}
.background{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.6;
  z-index: 1;
}
.background.confirm {
  z-index: 2;
}

.hover-box.confirm-container {
  top: 20%;
  left: 50%;
  width: 30rem;
  transform: translate(-50%);
  z-index: 2;
}
.hover-box.confirm-container .buttons {
  display: flex;
  justify-content: space-between;
}
.confirm-container h4 {
  margin-top: 0;
}

/* Header styling */
header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 0 4rem 0;
}

.header-field-logo {
  justify-self: start;
  align-self: center;
}

.header-field-title {
  justify-self: center;
  align-self: center;
}

.header-field-button {
  justify-self: end;
  align-self: center;
}

/* LoginBox styling */
.login-box-container {
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
}
.login-box-container .input-control {
  margin-bottom: 1rem;
}
.login-box-container .input-control input {
  margin-left: 2rem;
  width: 10rem;
}

/* TableNav styling */
.table-nav-container {
}

.table-nav-container form {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--wide-spacing);
}

.table-nav-container .table-nav-child label {
  margin-right: var(--wide-spacing);
  font-size: xx-large;
  font-weight: bold;
}

.table-nav-input {
  width: 100%;
  height: 100%;
  margin: 0 var(--standard-spacing);
}

.table-nav-container .table-nav-input input {
  font-size: x-large;
  width: 100%;
  height: 100%;
  padding: 0.4rem 1rem;
}

.table-nav-buttons {
  display: flex;
}

.table-nav-buttons .filter-button {
  margin-right: var(--standard-spacing);
}

.filter-container {
  overflow: hidden;
}

/* Filter styling */
.filter-container .filter-form{
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr 1fr 1fr 0.25fr;
  grid-template-rows: auto;
  column-gap: 10%;
  grid-template-areas:
    "from .        .        ."
    "to   .        .        ."
    "dep  building room     ."
    "id   class    teacher  reset";
}

.filter-container .input-control.from-filter {
  grid-area: from;
}
.filter-container .input-control.to-filter {
  grid-area: to;
}
.filter-container .input-control.department-filter {
  grid-area: dep;
}
.filter-container .input-control.building-filter {
  grid-area: building;
}
.filter-container .input-control.room-filter {
  grid-area: room;
}
.filter-container .input-control.id-filter {
  grid-area: id;
}
.filter-container .input-control.class-filter {
  grid-area: class;
}
.filter-container .input-control.teacher-filter {
  grid-area: teacher;
}
.filter-container .reset-button{
  grid-area: reset;
}

/* Table stylying */
.table-page-navigator {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  margin: 0 auto;
}

.table-page-buttons {
  display: flex;
}

.table-body {
  display: grid;
  grid-template-columns: 0.5fr 0.6fr 0.4fr 0.4fr 0.5fr 1fr 1fr 1.5fr 1fr 0.5fr 0.5fr;
  grid-template-rows: auto;
}

.table-head {
  border-bottom: 3px solid black;
}
.table-head h2 {
  margin: 0.75rem 0.5rem;
}

.table-entry {
  font-size: normal;
  border-bottom: 1px solid lightgray;
}
.table-entry.hidden {
  background: rgba(180, 180, 180, 0.5);
}

.table-entry p {
  margin: var(--standard-spacing);
}

.table-entry.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-entry.icons > *:hover {
  color: gray; 
  cursor: pointer;
}

.table-entry.icons .delete:hover {
  color: var(--juv-red); 
}

/* Edit Event stylying */
.edit-event-container {
  top: 20%;
  left: 10%;
  width: 70rem;
}

.edit-event-container form {
  display: grid;
  max-width: 100%;
  column-gap: var(--standard-spacing);
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "from   from-time ."
    "to     to-time   ."
    "dep    building  room"
    "class  teacher   subject";
  margin-bottom: var(--wide-spacing);
}
.edit-event-container .input-control.from-field {
  grid-area: from;
}
.edit-event-container .input-control.from-time-field {
  grid-area: from-time;
  margin-left: 6.3rem;
}
.edit-event-container .input-control.from-field input {
  width: 10rem;
}
.edit-event-container .input-control.from-time-field input {
  width: 7rem;
}
.edit-event-container .input-control.to-field {
  grid-area: to;
}
.edit-event-container .input-control.to-time-field {
  grid-area: to-time;
  margin-left: 6.3rem;
}
.edit-event-container .input-control.to-field input {
  width: 10rem;
}
.edit-event-container .input-control.to-time-field input {
  width: 7rem;
}
.edit-event-container .input-control.department-field {
  grid-area: dep;
}
.edit-event-container .input-control.building-field {
  grid-area: building;
}
.edit-event-container .input-control.room-field {
  grid-area: room;
}
.edit-event-container .input-control.class-field {
  grid-area: class;
}
.edit-event-container .input-control.teacher-field {
  grid-area: teacher;
}
.edit-event-container .input-control.subject-field {
  grid-area: subject;
}

.edit-event-container .buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--wide-spacing);
}

.edit-event-container h3 {
  margin-bottom: var(--standard-spacing); 
}

.edit-event-container .info {
  display: flex;
  width: 30%;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
}

/* User Manager */
.user-manager-container {
  padding: 0;
  left: 100%;
  width: 25rem;
  border: none;
  overflow: hidden;
}

.user-manager-container .content h2 {
  margin: 1rem 1rem 1rem var(--standard-spacing);
}

.user-manager-container .other-users, 
.user-manager-container .current-user {
  margin: 1rem var(--standard-spacing);
}

.user-manager-container .other-users {
  padding-right: 5px;
  max-height: 15rem;
  overflow-y: auto;
}

.user-manager-container .user {
  display: grid;
  grid-template-columns: 1.25fr 0.75fr 0.75fr;
  grid-template-rows: auto;
  column-gap: var(--standard-spacing);
}

.user-manager-container .user div {
  display: flex;
  align-items: center;
  height: 1.5rem;
  margin-bottom: var(--standard-spacing);
}

.user-manager-container .user p {
  margin: 0 0 0 var(--standard-spacing);
}

.user-manager-container .user .label-box {
  height: 100%;
}

.user-manager-container .user .label {
  font-size: small;
  font-weight: bold;
}

.user-manager-container .user .input-control {
  grid-column: 2 / 4;
}

.banner-button button {
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;
}

.add-user-container {
  overflow: hidden;
  margin: var(--standard-spacing);
}

.add-user-container input {
  width: 10rem;
}

.add-user-container .juv-button {
  margin-top: var(--standard-spacing);
}

/* Logs styling */
.log-table {
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
}

.log-table-data-container {
  overflow-y: auto;
  height: 75%;
  margin-bottom: var(--wide-spacing);
}

.log-table-data {
  display: grid;
  grid-template-columns: 0.25fr 0.1fr 1fr;
}

.log-table-data .log-table-head {
  font-weight: bold;
}

.log-table-data .log-table-entry p {
  margin: var(--standard-spacing) 0;
}

.log-table .button-div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.log-button {
  padding: 0;
  left: 100%;
  width: 8rem;
  border: none;
  overflow: hidden;
}
